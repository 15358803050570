import { breakpoints } from 'src/styles/breakpoints';
import { grayscale, orange, white } from 'src/styles/colors';
import styled, { css } from 'styled-components';

type OrangeColor = {
  isOrange?: boolean;
}

export const Container = styled.div`
  background: ${white};
  min-height: 100vh;
  padding: 30px 24px;
  max-width: 360px;
  position: relative;

  @media (min-width: ${breakpoints.md}) {
    top: 10vh;
    bottom: 10vh;
    padding: 24px 24px;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.08);
    border-radius: 2px;
    max-width: 640px;
    min-height: 375px;
    border-radius: 8px;
  }
`

export const CloseButton = styled.button`

  z-index: 10;
`

export const ScrollDiv = styled.div`
  h2 {
    font-family: "Sora", Helvetica, sans-serif;
  }

  @media (min-width: ${breakpoints.md}) {
    display: block;
    height: 630px;
    overflow-y: scroll;
    scroll-behavior: smooth;
    padding: 32px 40px;
  }

  @media (min-width: ${breakpoints.lg}) {
    height: 514px;
  }

  @media (min-width: ${breakpoints.xl}) {
    height: 660px;
  }
`

export const BoxConditions = styled.div`
  border-radius: 16px;
  border: 1px solid ${grayscale[200]};
  padding: 10px 16px;

  @media (min-width: ${breakpoints.md}) {
    width: 312px;
  }

  div {
    padding-bottom: 9px;
  }

  div:last-child() {
    padding-bottom: 0px;
  }

  hr {
    border: 1px dashed ${grayscale[200]};
  }
`

export const StarterTimeline = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 34px;

  @media (min-width: ${breakpoints.md}) {
    width: 312px;
  }
`

export const MonthTax = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 16px;

    @media (min-width: ${breakpoints.md}) {
      width: 312px;
    }
`

export const TitleDescription = styled.span`
  padding-left: 12px;
`

export const TitleDiv = styled.div`
  div {   
    span {
      padding: 0;
      margin-left: -7px;
      margin-right: 10px;
      text-align: center;
    }
  }
`

export const DotSymbol = styled.div<OrangeColor>`
  min-width: 8px;
  min-height: 8px;
  border-radius: 50%;
  background: ${grayscale[300]};

  ${(props: OrangeColor) => props.isOrange && css`
    background: ${orange.extra};
  `}
`

export const BoxDescription = styled.div`
  min-height: 32px;
  border-left: 2px solid ${grayscale[200]};
  margin-left: 3px;
  padding-left: 24px;
`

export const AlertBox = styled.div`
  background-color: ${grayscale[100]};
  padding: 16px;
  border-radius: 8px;

  div svg {
    width: 16px;
    height: 16px;
  }
`
